import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {Box} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {saveFile} from '../../../utils/file';
import {CloseSnackbarButton} from '../../common/CloseSnackbarButton';
import ExportFieldForm from '../../common/ExportFieldForm';
import ModalFixed from '../../common/ModalFixed';
import SnackbarMessages from '../../common/SnackbarMessages';

interface Props {
  reportDate: string | undefined;
}

const ShaftClearanceAssetsExportExcel = ({reportDate}: Props) => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [isFetching, setIsFetching] = useState(false);

  const [isOpened, setIsOpened] = useState(false);

  const exportFieldOptions = [
    {field: 'name', label: 'Name', hidden: false},
    {field: 'network_id', label: 'Network ID', hidden: false},
    {field: 'type', label: 'Type', hidden: false},
    {field: 'timestamp', label: 'Last Reported Timestamp', hidden: false},
    {field: 'date', label: 'Time of Data Collection', hidden: false},
    {field: 'shift', label: 'Shift', hidden: false},
    {field: 'section', label: 'Section', hidden: false},
    {field: 'position', label: 'Position', hidden: false},
    {field: 'latitude', label: 'Latitude', hidden: false},
    {field: 'longitude', label: 'Longitude', hidden: false},
    {field: 'strongest_node', label: 'Strongest Node', hidden: false},
  ];

  const checkFields = () => {
    setIsOpened(true);
  };

  const fetchData = async (fields: string[]) => {
    setIsOpened(false);
    setIsFetching(true);

    try {
      const params = {
        export: 'excel',
        fields,
        date_end: reportDate,
      };

      const resp = await API.get(`${apiBaseUrl}/shaft-clearance/assets`, {
        params,
        responseType: 'blob',
      });
      saveFile(resp.data, `DailyAssetLocation-${reportDate}.xlsx`);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setIsFetching(false);
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" justifyContent="end">
        <FileDownloadIcon onClick={() => checkFields()}></FileDownloadIcon>
      </Box>
      <ModalFixed open={isOpened} onClose={() => setIsOpened(false)}>
        <Box
          display="flex"
          flexDirection="column"
          position="relative"
          gap={3}
          p={3}
        >
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center" width="100%" gap={1.5}>
              <ExportFieldForm
                exportFields={exportFieldOptions}
                onChange={fetchData}
                close={() => setIsOpened(false)}
                loading={isFetching}
                columns={3}
              ></ExportFieldForm>
            </Box>
          </Box>
        </Box>
      </ModalFixed>
    </Box>
  );
};

export default ShaftClearanceAssetsExportExcel;
